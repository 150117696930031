<template>
  <div class="paypalsub">
    <div class="form-group" v-if="formError">
      <div class="error-container">
        <p class="error">{{ formError }}</p>
      </div>
    </div>
    <!-- <button @click="paymentInit()">
      <span v-if="!loader">PAYPAL</span>
      <Loading v-else></Loading>
    </button> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import Utility from "@/mixins/Utility.js";
export default {
  props: {
    gatewaydetail: {
      type: Object,
    },
    paymentDetail: {
      type: Object,
    },
  },
  data() {
    return {
      loader: false,
      formError: null,
			planAndDiscount: null,
			//planDiscountCheckout: null,
			subscriberDetails: null,
			currentUserCountry: null
    };
  },
  created() {
    console.log("paypal sub gatewaydetail", this.gatewaydetail);
    console.log("paypal sub paymentdetail", this.paymentDetail);
		//this.planAndDiscount = this.appConfig.planDiscount;
		this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")) ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
    console.log("subscriberDetails", this.subscriberDetails);
    let subscribedUserCountry = this.subscriberDetails ? this.subscriberDetails.data?.subscribercountry : null;
    console.log("77777", subscribedUserCountry);
    this.currentUserCountry = localStorage.getItem("currentUserCountry")
        ? JSON.parse(localStorage.getItem("currentUserCountry"))
        : null;
	  this.planAndDiscount = this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] ? this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] : this.appConfig.planDiscount["DEFAULT"];
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions(["paymentInitiation"]),
    paymentInit() {
      let currentDomain = window.location.origin;
			let payload;
		// if(this.paymentDetail.detail.planid === "2NJKC6IT"){
		
		//   payload = {
    //     gatewayid: this.gatewaydetail.gatewayid,
    //     params: {
    //       amount: this.planAndDiscount.offerPrice,
    //       currency: this.paymentDetail.detail.currency,
    //       devicetype: "WEB",
    //       transactionpurpose: this.paymentDetail.transactionPurpose,
    //       transactionmode: "CC",
    //       availabilityid: this.paymentDetail.detail.availabilityset[0],
    //       planid: this.paymentDetail.detail.planid,
    //     },
    //   };
		// }else {
		  payload = {
        gatewayid: this.gatewaydetail.gatewayid,
        params: {
          amount: this.paymentDetail.detail.amount,
          currency: this.paymentDetail.detail.currency,
          devicetype: "WEB",
          transactionpurpose: this.paymentDetail.transactionPurpose,
          transactionmode: "CC",
          availabilityid: this.paymentDetail.detail.availabilityset[0],
          planid: this.paymentDetail.detail.planid,
        },
      };
		//}

      payload.params.client_return_url =
        currentDomain + "/profile" + "?" + "planid=" + this.paymentDetail.detail.planid;

      console.log("paypal sub payment init request", payload);

      this.loader = true;
      this.paymentInitiation(payload)
        .then((response) => {
          this.loader = false;
          console.log("paypal sub paymentinit response", JSON.stringify(response));
          if (!response.data.reason) {
            const browser = this.getPlatformType();

            const theSource = localStorage.getItem("payment_source");

            let paymentDetailsForGA = {
              free_trial: this.getFreeTrial(),
              payment_mode: this.getPaymentMode(response.data.referencedata.gwprovider),
              plan_id: this.paymentDetail.detail.planid,
              plan_name: this.paymentDetail.detail.planname,
              payment_id: response.data.referencedata.paymentid,
              source: theSource,
              object_id: "",
              object_name: "",
              platform: this.getPlatform(),
              platform_type: browser.browser,
            };

            // Google Analytics Event
            this.subscriptionStarted(paymentDetailsForGA);

            // Facebook analytics Event
            this.faceBookInitateCheckoutEvent(paymentDetailsForGA);

            window.open(response.data.referencedata.approveUrl, "_self");
          } else {
            this.formError = response.data.reason;
          }
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility, googleAnalytics, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_components.scss";
.paypalsub {
  margin: 20px 0;
  button {
    padding: 10px;
    width: 100%;
    border: 1px solid #009cde;
    border-radius: 4px;
    background: #009cde;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
